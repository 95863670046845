import React from 'react'

const FeaturesSection = () => {
    return (
           <section className="features-section" id='section-3'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="features-blk">
                                <h2>Discover The Features</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-xl-10">
                            <div className="feature-card">
                                <div className="feature-card-img" data-aos="zoom-in-up" data-aos-duration="2500">
                                    <img src="assets/images/feature-img-1.png" alt="Images" />
                                </div>
                                <div className="feature-card-inner">
                                    <h3> Platform for personal stories</h3>
                                    <p>At Lumakin, crafting the story of your life is more than a pastime; it's a way to etch your legacy into the fabric of time. With a focus on preserving your individual experiences, our platform offers a special home for the personal stories that define you. Create a living document of who you are, beyond just names and dates, ensuring your descendants know the person behind the family tree. </p>
                                </div>
                            </div>
                            <div className="feature-card">
                                <div className="feature-card-img" data-aos="zoom-in-up" data-aos-duration="2500">
                                    <img src="assets/images/feature-img-2.png" alt="Images" />
                                </div>
                                <div className="feature-card-inner">
                                    <h3>Capture your voice in text, audio or video </h3>
                                    <p>Capture the essence of your being—your voice, laughter, and wisdom—in a format that stands the test of time. With Lumakin, your personal and family memories are protected and cherished. From the subtle inflections in your storytelling to the joyous laughter in family videos, we keep the sensory richness of your memories intact for your loved ones to experience. </p>
                                </div>
                            </div>
                            <div className="feature-card">
                                <div className="feature-card-img" data-aos="zoom-in-up" data-aos-duration="2500">
                                    <img src="assets/images/feature-img-3.png" alt="Images" />
                                </div>
                                <div className="feature-card-inner">
                                    <h3>Single location for all memories </h3>
                                    <p>Lumakin invites you to bring your individual journey to life by sharing the moments and insights that are uniquely yours. This sharing creates a vibrant patchwork of perspectives, reflecting the diverse experiences that shape our existence. By inviting others to share, we foster a collective heritage enriched with varied insights and reflections, crafting a fuller, richer narrative. </p>
                                </div>
                            </div>
                            <div className="feature-card">
                                <div className="feature-card-img" data-aos="zoom-in-up" data-aos-duration="2500">
                                    <img src="assets/images/feature-img-4.png" alt="Images" />
                                </div>
                                <div className="feature-card-inner">
                                    <h3>Explore family historical timeline</h3>
                                    <p>Explore your lineage with tools that delve into your family's past. Lumakin's advanced genealogy features not only help to trace your roots but also to weave your family's historical data into a continuous narrative. Watch as your shared history unfolds over time, capturing the evolution and growth of your lineage on a dynamic family timeline. </p>
                                </div>
                            </div>
                            <div className="feature-card">
                                <div className="feature-card-img" data-aos="zoom-in-up" data-aos-duration="2500">
                                    <img src="assets/images/feature-img-5.png" alt="Images" />
                                </div>
                                <div className="feature-card-inner">
                                    <h3> Social Media with Purpose </h3>
                                    <p>Navigate Lumakin's interface with the familiarity of social media, designed for ease of use and enriched by meaningful interaction. Document the landmarks and daily nuances of your life's journey, enjoying the camaraderie and connection of a social platform while building a living record of your personal history. </p>
                                </div>
                            </div>
                            <div className="feature-card">
                                <div className="feature-card-img" data-aos="zoom-in-up" data-aos-duration="2500">
                                    <img src="assets/images/feature-img-6.png" alt="Images" />
                                </div>
                                <div className="feature-card-inner">
                                    <h3>Self-discovery of your life's chapters </h3>
                                    <p>With Lumakin's structured prompts, crafting your autobiography is a journey of self-discovery. Our guidance helps you recount the depth of your personal story, ensuring that the full panorama of your life's experiences is captured. These guided processes help to build a comprehensive narrative that stands as a testimony to your lived experiences.</p>
                                </div>
                            </div>
                            <div className="feature-card">
                                <div className="feature-card-img" data-aos="zoom-in-up" data-aos-duration="2500">
                                    <img src="assets/images/feature-img-7.png" alt="Images" />
                                </div>
                                <div className="feature-card-inner">
                                    <h3>Personal Insights Through Self- guided Interviews </h3>
                                    <p>Gain a new perspective on your life with Lumakin's self-guided interview feature. Schedule and conduct interviews at your leisure, creating a detailed account of your personal history for future generations to cherish. These interviews are a valuable way for other family members to share stories, preserving a wealth of knowledge for those yet to come. </p>
                                </div>
                            </div>
                            <div className="feature-card">
                                <div className="feature-card-img" data-aos="zoom-in-up" data-aos-duration="2500">
                                    <img src="assets/images/feature-img-8.png" alt="Images" />
                                </div>
                                <div className="feature-card-inner">
                                    <h3>Organizing Your Digital Memories </h3>
                                    <p>Our platform provides a sanctuary for your digital memories, offering a robust system to upload, categorize, and manage a rich array of media files. Build and share your digital albums that tell the story of your life through pictures, videos, and sounds—each piece a brushstroke in the masterpiece of your timeline.
                                    </p>
                                </div>
                            </div>
                            <div className="feature-card">
                                <div className="feature-card-img" data-aos="zoom-in-up" data-aos-duration="2500">
                                    <img src="assets/images/feature-img-9.png" alt="Images" />
                                </div>
                                <div className="feature-card-inner">
                                    <h3> Unified location for storage and reliving memories </h3>
                                    <p> Lumakin is your central hub for sharing and reliving memories with those who matter most. It's a place where collective recollections foster connection and a sense of belonging. This centralized platform is designed to be a secure, shared space where life's stories can be kept alive through the contributions of every user. </p>
                                </div>
                            </div>
                            <div className="feature-card">
                                <div className="feature-card-img" data-aos="zoom-in-up" data-aos-duration="2500">
                                    <img src="assets/images/feature-img-10.png" alt="Images" />
                                </div>
                                <div className="feature-card-inner">
                                    <h3>Secure and private environment </h3>
                                    <p>We understand the importance of privacy in preserving your personal history. Lumakin offers a secure and private environment for you to share your life's stories on your terms. Control who sees what, ensuring that your personal recollections remain as intimate or as public as you wish, within a digital space that prioritizes the integrity of your narrative. </p>
                                </div>
                            </div>
                            <div className="feature-card">
                                <div className="feature-card-img" data-aos="zoom-in-up" data-aos-duration="2500">
                                    <img src="assets/images/feature-img-11.png" alt="Images" />
                                </div>
                                <div className="feature-card-inner">
                                    <h3>Collaborate and invite other to share their perspective  </h3>
                                    <p>Create a living legacy with those you love on Lumakin. The platform encourages collaborative storytelling, inviting your circle to add to the narrative, enriching your story with their own. As each person contributes, your collective memory grows, forming a rich legacy woven directly into the fabric of your timeline. </p>
                                </div>
                            </div>
                            <div className="feature-card">
                                <div className="feature-card-img" data-aos="zoom-in-up" data-aos-duration="2500">
                                    <img src="assets/images/feature-img-12.png" alt="Images" />
                                </div>
                                <div className="feature-card-inner">
                                    <h3>View information in multimedia timeline </h3>
                                    <p>Your story is not just told but shown on Lumakin, where every entry contributes to a personalized, multimedia timeline. Enrich your story with images, videos, and reflections that bring context and color to your biography. Visual storytelling becomes effortless, creating a dynamic portrait of your life's unfolding saga. </p>
                                </div>
                            </div>
                            <div className="feature-card">
                                <div className="feature-card-img" data-aos="zoom-in-up" data-aos-duration="2500">
                                    <img src="assets/images/feature-img-13.png" alt="Images" />
                                </div>
                                <div className="feature-card-inner">
                                    <h3>Choose visibility by defining relationship circles</h3>
                                    <p>On Lumakin, you're the curator of your life's exhibit, deciding who is privy to the chapters of your personal saga. With our fully customizable circles of influence, exercise the freedom to share privately, with family, with select friends, or not at all. Your entries reflect your choices, creating a narrative shared on your own terms, with the audience you choose.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
       
    )
}

export default FeaturesSection;
