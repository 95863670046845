import React, { useState } from 'react'
import { FeaturesArray } from '../seeds'
import { useFormik } from 'formik';
import { waitlistInitialValues, waitlistValidationSchema } from '../validations/schema';
import CryptoJS from 'crypto-js';
import axios from 'axios';
import { toast } from 'react-toastify';
import { numberOnly } from '../utils/helpers';

const WaitLiastModal = ({showModal, setShowModal}) => {
    const [openModal, setOpenModal] = useState(false)

    const [chipData, setChipData] = useState("");

    //Encryption function

  function encrypt(text) {
      const key = process.env.REACT_APP_AUTH_TOKEN;
      const keyBytes = CryptoJS.enc.Hex.parse(key);
      const iv = CryptoJS.lib.WordArray.random(16);
      const encrypted = CryptoJS.AES.encrypt(
        CryptoJS.enc.Utf8.parse(text),
        keyBytes,
        { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
      );
    
      return iv.toString() + ':' + encrypted.ciphertext.toString(CryptoJS.enc.Hex);
    } 
    
    // ---------

    const { values, touched, errors, handleBlur, handleChange, handleSubmit, setValues, resetForm } = useFormik({

        initialValues: waitlistInitialValues,
        validationSchema: waitlistValidationSchema,
        onSubmit:async (values) => {
           
            let data = {
                "name":values.firstName + values.lastName,
                "phoneNumber":values.mobileNumber,
                "email":values.emailAddress,
                "feature":values.selectedFeatures,
                "otherFeatures":values.otherFeatures.map((data)=>data.name),
                "type":"1"
            };
          try {
           
            let encryptedData = encrypt(data);
             axios.post(
                "https://lumakin-dev.csdevhub.com/v1/api/email/",
             data,{
                headers:{
                    Authorization:encryptedData
                }
             })
             setOpenModal(true)
             toast.success("Email sent successfully");
             } catch (error) {
            console.log("error 07>>> ",error);
          }
        }
    });

    const handleChipChange = (e) => {
        setChipData(e.target.value);
    }

    const handleFeatureChange = (featureId, isChecked) => {
        setValues((prevValues) => {
            const { selectedFeatures } = prevValues;
            let updatedFeatures;

            if (isChecked) {
                updatedFeatures = [...selectedFeatures, featureId];
            } else {
                updatedFeatures = selectedFeatures.filter(id => id !== featureId);
            }

            return { ...prevValues, selectedFeatures: updatedFeatures };
        })

    };

    const addChipData = () => {
        if (chipData.trim() !== "") {

            setValues((prev) => {
                const updatedValues = { ...prev };
                const newChip = {name:  chipData };

                // Add the new chip to the otherFeatures array
                updatedValues.otherFeatures = [...updatedValues.otherFeatures, newChip];

                return updatedValues;
            });
            setChipData("")
            console.log(values.otherFeatures);
        }
    };

    const removeItem = (index) => {
        setValues((prev) => {
            const updatedValues = { ...prev };
            updatedValues.otherFeatures = prev.otherFeatures.filter((item, i) => i !== index)

            return updatedValues
        })

 }
    
 return (
   
        <div className="modal fade common-modal p-0 " id="formModal"  tabIndex={-1} aria-labelledby="formModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body p-0">
                        <div className="common-wrapper">
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={() => {
                                    setOpenModal(false)
                                    resetForm()
                                }}
                            />
                            <div className={openModal === false ? "signup-wrapper" : "signup-wrapper d-none"}>
                                <div className="form-header">
                                    <h2 className="modal-title">Be the first to know about our new app!</h2>
                                    <p>Join our waitlist and stay informed about our progress, features, surveys, and beta opportunities.</p>
                                </div>

                                <div className="form-wrapper"  >
                                    <form >
                                        <div className="signup-form-blk">
                                            <div className="row g-3">
                                                <div className="col-sm-6">
                                                    <div className="signup-fields">
                                                        <input
                                                            value={values?.firstName}
                                                            onChange={handleChange}
                                                            name='firstName'
                                                            onBlur={handleBlur}
                                                            type="text"
                                                            className="form-control"
                                                            id="signupFname"
                                                            placeholder="first name"
                                                        />
                                                        {errors?.firstName && touched?.firstName && <p className='error pt-0 px-2'>{errors.firstName}</p>}
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="signup-fields">
                                                        <input value={values?.lastName}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            name='lastName'
                                                            type="text"
                                                            className="form-control"
                                                            id="signupLname" placeholder="last name" />
                                                        {errors?.lastName && touched?.lastName && <p className='error pt-0 px-2'>{errors.lastName}</p>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="signup-fields">
                                                        <input value={values?.mobileNumber}
                                                            onChange={handleChange}
                                                            name='mobileNumber'
                                                            onBlur={handleBlur}
                                                            onKeyPress={numberOnly}
                                                            maxLength={10}
                                                            type="tel" className="form-control" id="signupNumber" placeholder="Mobile number" />
                                                        {errors?.mobileNumber && touched?.mobileNumber && <p className='error pt-0 px-2'>{errors.mobileNumber}</p>}
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="signup-fields">
                                                        <input value={values?.emailAddress}
                                                            onChange={handleChange}
                                                            name='emailAddress'
                                                            onBlur={handleBlur}
                                                            type="email" className="form-control" id="signupEmail" placeholder="Email address" />
                                                        {errors?.emailAddress && touched?.emailAddress && <p className='error pt-0 px-2'>{errors.emailAddress}</p>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="select-feature-blk">
                                                        <h2>Indicate the features that you are most excited about</h2>
                                                        {errors?.selectedFeatures && touched?.selectedFeatures && <p className='error pt-0 px-1'>{errors.selectedFeatures}</p>}
                                                        <div className="featureslist">
                                                            {
                                                                FeaturesArray.map((item) => (
                                                                    <div className="features-list-item">
                                                                        <input type="checkbox" id={`feature-${item.id}`}
                                                                            onChange={e => handleFeatureChange(item.title, e.target.checked)}
                                                                            checked={Array.isArray(values?.selectedFeatures) && (values.selectedFeatures?.includes(item?.title))}
                                                                        />
                                                                        <label htmlFor={`feature-${item.id}`}>
                                                                            <span className="feature-check" />
                                                                            <span className="feature-heading">{item.title}</span>
                                                                        </label>
                                                                    </div>
                                                                ))
                                                            }


                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="append-block">
                                                        <ul className="appendlist-wrapper">
                                                            {
                                                                Array.isArray(values?.otherFeatures) &&
                                                                values?.otherFeatures?.map((item, index) => (
                                                                    <li className="appendlist">
                                                                        <span className="addedfeatuire">{item?.name}</span>
                                                                        <button
                                                                            type="button"
                                                                            className="removefeature">
                                                                            <i onClick={() => removeItem(index)} className="fa-solid fa-xmark" />
                                                                        </button>
                                                                    </li>
                                                                ))
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12">
                                                    <div className="signup-fields p-relative">
                                                        <input
                                                            value={chipData}
                                                            type="text"
                                                            className="form-control"
                                                            id="others"
                                                            placeholder="other features"
                                                            onChange={handleChipChange}
                                                            maxLength={90}
                                                            onBlur={handleBlur}
                                                        />
                                                        <button className="addfeatures" type="button" onClick={addChipData}>+Add</button>
                                                    </div>
                                                    {errors?.otherFeatures && touched?.otherFeatures && <p className='error pt-0 px-2'>{errors.otherFeatures}</p>}
                                                    <span className="text-title">Max length(90 words)</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="signup-form-btn">
                                            <button onClick={handleSubmit} type="button" className="signup-submit-btn" >Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div className={openModal === true ? "Congratulation-wrapper" : "Congratulation-wrapper d-none"}>
                                <div className="congrats-img">
                                    <img src="assets/images/fireworks.png" alt="Congratulations" />
                                </div>
                                <h2>Congratulations!</h2>
                                <p>You're now part of our exclusive community. Get ready for the latest updates
                                    on our upcoming application. Thank you for subscribing!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WaitLiastModal;
