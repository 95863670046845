import React from 'react'

const BlogSection = () => {
    return (
        <div>
            <section className="content-section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="content-blk">
                                <h2>Founder's History</h2>
                                <p>Ben and Heidi experienced a universal truth through personal loss: the rich, authentic stories of those we lose often remain untold or become obscured over time. They both experienced a longing for their loved one's voice or that song they always sang and clung to just one voicemail as the only option of hearing their voice or their stories. They observed that an individual's life story, filled with their emotions, thoughts, and unique viewpoints, may be overshadowed or forgotten among the well-intentioned but scattered memories shared by family and friends. This revelation sparked the creation of Lumakin—a dedicated space designed to safeguard the genuine narratives of individuals for posterity.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6 ps-lg-5">
                            <div className="img-wrapper" data-aos="zoom-in-up" data-aos-duration="2500">
                                <img src="assets/images/img-1.png" alt="Images" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default BlogSection;
