import React from 'react'

const AboutUsSection = () => {
    return (
        <div id='section-2'>
            <section className="content-section"  >
                <div className="container">
                    <div className="row gy-4 align-items-center">
                        <div className="col-lg-7">
                            <div className="content-blk">
                                <span className="heading-style">why lumakin</span>
                                <h2>Create, Share, &amp; <br />Preserve Your Unique Life Story with Lumakin! </h2>
                                <p>Imagine capturing every laugh, every adventure, and every sad goodbye. Lumakin was created for just that purpose—to ensure that the full spectrum of your life stories, from the triumphs to the tribulations and the downright funny, are never lost to time. It's time to ditch the polished façade of typical social media and embrace the real, the raw, and the incredibly personal. With Lumakin, your life is more than just a series of posts; it's an authentic narrative, rich with the moments that define you. </p>
                            </div>
                        </div>
                        <div className="col-lg-5 ps-lg-5">
                            <div className="img-wrapper" data-aos="zoom-in-up" data-aos-duration="2500">
                                <img src="assets/images/grid-img.png" alt="Images" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="innovative-section second-row" id='section-2' >
                <div className="container">
                    <div className="row gy-4 align-items-xl-end">
                        <div className="col-lg-6">
                            <div className="innov-img-wrapper" data-aos="zoom-in-up" data-aos-duration="2500">
                                <img src="assets/images/img-2.png" alt="Images" />
                            </div>
                        </div>
                        <div className="col-lg-6 ps-lg-5">
                            <div className="content-blk">
                                <p>Our platform is more than just a digital space—it's a sanctuary for your narrative. Unlike other social media and legacy platforms that focus on likes or documenting only positive narratives from your past, Lumakin encourages the spontaneous and reflective sharing of your life’s events. Whether through guided storytelling, event documentation, or reflective journaling, we give you the tools to weave your life's story as it unfolds. </p>

                                <p>Lumakin stands as a celebration of individuality, offering a sanctuary where every chapter of your life can be illuminated, connected with relevant media, and expressed through your own words. Here, you're not just creating a record; you're authoring a legacy that can be shared with loved ones and passed down through generations. </p>

                                <p>
                                    We hold the highest respect for the significance of each person's journey. Lumakin is dedicated to the collective creation and sharing of stories among friends and family. We safeguard your memories, allowing for future revelations of your life's journey and wisdom, ensuring that your legacy is more than remembered—it's immortalized. Join us as we craft an everlasting collective memoir that honors individual stories and forges eternal connections.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default AboutUsSection;
