export const RoutingNavigation = [
  {
    name: "Home",
    id: "section-1",
    path: "/",
  },
  {
    name: "Why Us",
    id: "section-2",
    path: "/why-us",
  },
  {
    name: "Features",
    id: "section-3",
    path: "/features",
  },
  {
    name: "Pricing",
    id: "section-4",
    path: "/pricing",
  },
  {
    name: "FAQ",
    id: "section-5",
    path: "/faq",
  },

  {
    name: "Contact Us",
    id: "section-6",
    path: "/contact-us",
  },
];

export const PremiumPriceArray = [
  {
    id: "1",
    amount: "29.99",
    oldAmount: "359.88",
    plan: "Monthly",
  },
  {
    id: "2",
    amount: "299.90",
    oldAmount: "359.88",
    plan: "Yearly",
  },
];

export const BasicPriceArray = [
  {
    id: "basic1",
    amount: "6.99",
    oldAmount: "399.90",
    plan: "Monthly",
  },
  {
    id: "basic2",
    amount: "69.99",
    oldAmount: "83.88",
    plan: "Yearly",
  },
];

export const FeaturesArray = [
  {
    id: 1,
    title: "Platform for personal stories "
  },
  {
    id: 2,
    title: "Capture your voice in text, audio or video "
  },
  {
    id: 3,
    title: "Single location for all memories "
  },
  {
    id: 4,
    title: "Explore family historical timeline "
  },
  {
    id: 5,
    title: "Social Media with Purpose "
  },
  {
    id: 6,
    title: "Self-discovery of your life's chapters "
  },
  {
    id: 7,
    title: "Personal Insights Through Self- guided Interviews "
  },
  {
    id: 8,
    title: "Organizing Your Digital Memories "
  },
  {
    id: 9,
    title: " Unified location for storage and reliving memories  "
  },
  {
    id: 10,
    title: "Secure and private environment "
  },

  {
    id: 11,
    title: "Collaborate and invite other to share their perspective "
  },
  {
    id: 12,
    title: "View information in multimedia timeline"
  },
  {
    id: 13,
    title: "Choose visibility by defining relationship circles "
  },


]