import { useEffect } from 'react';
import './App.css';
import Footer from './components/footer';
import Header from './components/header';
import HomeScreen from './screens/homeScreen';
import "aos/dist/aos.css";
import AOS from "aos";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function App() {

  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <div>
      <Header />
      <HomeScreen />
      <Footer />
      <ToastContainer
       icon={({ type }) => {
        if (type === "success") return <img src='./favicon.png' alt='...'/>;
        if (type === "error") return null;
        else return "ℹ️";
    }}
 progressStyle={{backgroundColor:"#30C6CC",}}
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light" 
       />
    </div>
  );
}

export default App;